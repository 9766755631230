import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "oxygen"
    }}>{`Oxygen`}</h1>
    <h2 {...{
      "id": "in-yeast-reproduction"
    }}>{`In yeast reproduction`}</h2>
    <p>{`oxygen -> synthesis sterols and UFA(unsaturated fatty acids)-> lipid membranes ->  new yest `}{`[1]`}</p>
    <p>{`Too much oxygen is rarely a problem, but yeast do not need too much oxygen, it does not reslt in excess sterols. Instead, the yeast use the oxygen
for making more flavor compounds`}</p>
    <p>{`oxygen for average wort and yeast pitching rate is 8 to 10 ppm (parts per million)`}</p>
    <p>{`Excessive usage of pure oxygen results in high levels of fuse alcohols, increased acetaldehyde, and other flavor problems.`}</p>
    <p>{`Increasing the oxygen level pass 9 ppm increase the pace of fermentation slightly but it ended up at the same terminal gravity.`}</p>
    <h3 {...{
      "id": "high-gravity-beer"
    }}>{`High Gravity Beer`}</h3>
    <p>{`If the gravity of a beer is more than 1.092( 22 `}{`°`}{`P), it is a must to aerate with pure oxygen,
as air will not provide a high enough level of dissolved oxygen. and that might not be enough,
beer need adding a extra dose of exygen for 12 - 18 hours for help fermentation speed and attenuation.
why 12 hours is because we need wait for the yeast to complete at least first cell division. `}</p>
    <p>{`References:`}</p>
    <p>{`[1]`}{` p76 Yeast`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      